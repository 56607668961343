/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { Helmet } from "react-helmet";
import { graphql, navigate } from "gatsby";
import { uniq, isNull, findIndex } from "lodash";
import {
  useRecoilState,
  useResetRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { v4 as uuidv4 } from "uuid";

import {
  customStepAtom,
  selectedItemAtom,
  itemAtom,
  cartAtom,
  showCartAtom,
} from "../utils/atoms";
import { priceFromIdSelector } from "../utils/selectors";
import { useShopifyFunctions } from "../utils/useShopifyFunctions";

import Layout from "../layout";
import Input from "../components/Input/Input";
import MenuNav from "../components/MenuNav/MenuNav";
import StepWrap from "../components/StepWrap/StepWrap";
import Summary from "../components/Summary/Summary";
import Choice from "../components/Choice/Choice";
import Canvas from "../components/Canvas/Canvas";
import Fonts from "../components/Fonts/Fonts";
import Sizes from "../components/Sizes/Sizes";
import Typestyles from "../components/Typestyles/Typestyles";
import Colors from "../components/Colors/Colors";
import Icons from "../components/Icons/Icons";
import Extras from "../components/Extras/Extras";

// const stepOrder = [
//   "custom-type",
//   "custom-name",
//   "custom-font",
//   "custom-typestyle",
//   "custom-icons",
//   "custom-color",
//   "custom-size",
//   "custom-tag",
//   "custom-summary",
// ];

const stepOrder = [
  { label: "Pet Name", value: "custom-name" },
  { label: "Font", value: "custom-font" },
  { label: "Type Style", value: "custom-typestyle" },
  { label: "Icons", value: "custom-icons" },
  { label: "Color Palette", value: "custom-color" },
  { label: "Size", value: "custom-size" },
  { label: "Extras", value: "custom-extras" },
  { label: "Summary", value: "custom-summary" },
];

function CollarlabCustom({ location, data }) {
  const { shopifyId, variants, title } = data.shopifyProduct;
  const { addItem, getCheckout } = useShopifyFunctions();

  const [selectedItem, setSelectedItem] = useRecoilState(selectedItemAtom);
  const resetSelected = useResetRecoilState(selectedItemAtom);
  const [item, setItem] = useRecoilState(itemAtom(selectedItem));
  const [cart, setCart] = useRecoilState(cartAtom);
  const showCart = useSetRecoilState(showCartAtom);
  const price = useRecoilValue(priceFromIdSelector({ id: selectedItem }));

  if (!selectedItem || isNull(selectedItem)) {
    setSelectedItem(`cl-${uuidv4()}`);
  }

  const [customStep, setCustomStep] = useRecoilState(customStepAtom);

  const amount = 1;
  const addToCartHandler = () => {
    setCart(uniq([...cart, selectedItem]));
    showCart(true);
  };

  return (
    <Layout
      location={location}
      back={() => {
        navigate("/");
      }}
    >
      <>
        <Helmet title="Stuntpuppy" />
        <div className="flex h-screen">
          <div className="w-2/3 relative bg-gradient-radial from-white to-sp-md-gray">
            <Canvas />
            {/* <iframe
              className="w-full h-full"
              src="https://sp-vis-r.netlify.app/"
              title="visualizer"
            /> */}
            <div className="absolute right-0 top-0 bg-gradient-to-l from-black to-transparent h-full w-24 opacity-10	" />
           {cart.includes(selectedItem) && <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-8 bg-white py-2 px-8">
              You are editing an item already in your cart
            </div>}
          </div>

          <div className="w-1/3 h-screen relative border-black bg-white overflow-auto flex flex-col">
            {/* <div className="absolute bottom-0 mb-32 bg-gradient-to-t from-gray-300 to-white w-full h-16" /> */}

            <div className="relative h-full overflow-auto">
              {customStep === "custom-type" && (
                <StepWrap title="Select Product">
                  <Choice
                    value="collar"
                    name="product"
                    text="Collar"
                    checked={item.product === "collar"}
                    onChange={(e) =>
                      setItem({ ...item, product: e.target.value })
                    }
                  />
                  <Choice
                    value="leash"
                    name="product"
                    text="Leash"
                    checked={item.product === "leash"}
                    onChange={(e) =>
                      setItem({ ...item, product: e.target.value })
                    }
                  />
                  <Choice
                    value="bundle"
                    name="product"
                    text="Bundle"
                    checked={item.product === "bundle"}
                    onChange={(e) =>
                      setItem({ ...item, product: e.target.value })
                    }
                  />
                </StepWrap>
              )}

              {customStep === "custom-name" && (
                <StepWrap title="Pet Name">
                  <Input
                    label="What's your pet's name?"
                    name="petName"
                    placeholder="Ruby"
                    type="text"
                    value={item.name}
                    onChange={(e) =>
                      setItem({ ...item, name: e.target.value })
                    }
                  />
                </StepWrap>
              )}

              {customStep === "custom-font" && <Fonts />}

              {customStep === "custom-typestyle" && <Typestyles />}

              {customStep === "custom-icons" && <Icons />}

              {customStep === "custom-color" && <Colors />}

              {customStep === "custom-size" && (
                <Sizes item={item} set={setItem} />
              )}

              {customStep === "custom-extras" && (
                <Extras item={item} set={setItem} />
              )}

              {customStep === "custom-summary" && (
                <Summary item={item} set={setItem} />
              )}
            </div>

            <div className="relative bg-sp-gray w-full flex items-end h-32 p-8 pt-4">
              <MenuNav set={setCustomStep} current={customStep} steps={stepOrder} />
              <div className="flex flex-col items-center w-1/2">
                <div className="text-lg">${price}.00</div>
                {findIndex(stepOrder, {value: customStep}) < stepOrder.length - 1 ? (
                  <button
                    type="button"
                    className="w-full bg-sp-orange text-xl font-medium rounded-full text-white mt-2 px-8 py-2 ml-2"
                    onClick={() =>
                      setCustomStep(stepOrder[1 + findIndex(stepOrder, {value: customStep})].value)
                    }
                  >
                    Next
                  </button>
                ) : (
                  <button
                    type="button"
                    className="w-full bg-sp-orange text-xl font-medium rounded-full text-white mt-2 px-8 py-2 ml-2"
                    onClick={() => {
                      addToCartHandler(variants[0].shopifyId, amount, [
                        {
                          key: "id",
                          value: uuidv4(),
                        },
                        {
                          key: "pet_name",
                          value: "Ruby",
                        },
                        {
                          key: "font",
                          value: "Slackey",
                        },
                        {
                          key: "font_style",
                          value: "Stacked",
                        },
                        {
                          key: "icons",
                          value: "[]",
                        },
                      ]);
                    }}
                  >
                    {cart.includes(selectedItem) ? 'Back to Cart' : 'Add to Cart'}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
}

export const query = graphql`
  {
    shopifyProduct(shopifyId: {eq: "gid://shopify/Product/6573303758946"}) {
      shopifyId
      title
      options {
        name
        values
      }
      variants {
        title
        shopifyId
      }
      description
    }
  }
`;

export default CollarlabCustom;
