import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useRecoilState, useRecoilValue } from "recoil";
import { uniq, compact, pull, last, reduce, union, filter, includes, some, reject, first, } from "lodash";
import {
  CustomCheckbox,
  CustomCheckboxContainer,
  CustomCheckboxInput,
} from "@reach/checkbox";
import "@reach/checkbox/styles.css";

import StepWrap from "../StepWrap/StepWrap";
import { selectedItemAtom, itemAtom } from "../../utils/atoms";

const IconSelect = ({ name, checked, onChange, imgUrl }) => (
    <div
      className={`${checked ? "bg-sp-orange" : ""} rounded-full w-full`}
    >
      <CustomCheckboxContainer
        className="aspect-w-1 aspect-h-1"
        style={{ width: "100%", height: "auto", margin: 0, display: "block" }}
        checked={checked}
        onChange={onChange}
      >
        <CustomCheckboxInput checked={checked} />
        <img className="p-2" src={imgUrl} alt={name} />
      </CustomCheckboxContainer>
    </div>
  );

const Icons = () => {
  const selectedItem = useRecoilValue(selectedItemAtom);
  const [item, setItem] = useRecoilState(itemAtom(selectedItem));

  const data = useStaticQuery(graphql`
    query IconsQuery {
      iconList: allAirtable(
        filter: { table: { eq: "Icons" }, data: { Active: { eq: true } } }
      ) {
        edges {
          node {
            id
            data {
              name: Name
              value: Value
              file: File {
                localFiles {
                  id
                  publicURL
                }
              }
              tags:Tags
            }
          }
        }
      }
    }
  `);

const [selectedCategory, setSelectedCategory] = useState("");
const [filteredList, setFilteredList] = useState(data.iconList.edges);
const allTags = reduce(data.iconList.edges, (result, {node: { data: { tags }}}) => union(result, tags), [])

useEffect(() => {
  const everything = data.iconList.edges
  const filtered = filter(everything, ({ node: { data: { tags } } }) => { 
    if ( selectedCategory )  return includes(tags, selectedCategory)
    return true; 
  });
  setFilteredList(filtered)
}, [data.iconList.edges, selectedCategory]);


  return (
    <StepWrap title="Select Icons (0-2)">
      <div className="mx-4 flex flex-col items-center mb-4 text-xl max-w-3xl">
        <div className="flex flex-col w-full mb-4">
            <span className="uppercase font-bold text-sm mb-2">Category</span>
            <select 
              name="category" 
              id="category"
              className="rounded-full bg-sp-gray border-none mr-8 text-xl w-full"
              value={selectedCategory}
              onChange={e => setSelectedCategory(e.currentTarget.value)}
            >
              <option value="">All</option>
              {allTags.map( tag => <option value={tag}>{tag}</option>)}
            </select>
          </div>
        </div>
      <div className="grid grid-cols-4 gap-4 m-4">
        {filteredList.map(
          ({
            node: {
              id,
              data: { name, value, file },
            },
          }) => (
            <div
              className="w-full"
              key={id}
            >
              <IconSelect
                name={name}
                checked={some(item.icons, {value})}
                onChange={(e) => {
                  e.target.checked 
                    ? setItem({
                        ...item,
                        icons: compact(uniq([first(item.icons), {name, value, file: file.localFiles[0].publicURL}])),
                      })
                    : setItem({
                        ...item,
                        icons: compact(uniq(reject(item.icons, {value}))),
                      });
                }}
                imgUrl={file.localFiles[0].publicURL}
              />
            </div>
          )
        )}
      </div>
    </StepWrap>
  );
};

export default Icons;
