import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useRecoilState, useRecoilValue } from "recoil";

import { selectedItemAtom, itemAtom } from "../../utils/atoms";
import { fontFamily } from "../../utils/typeStyles";
import Choice from "../Choice/Choice";
import StepWrap from "../StepWrap/StepWrap";

const Fonts = () => {
  const selectedItem = useRecoilValue(selectedItemAtom);
  const [item, setItem] = useRecoilState(itemAtom(selectedItem));

  const data = useStaticQuery(graphql`
    query FontListQuery {
      fontList: allAirtable(
        filter: { table: { eq: "Fonts" }, data: { Active: { eq: true } } }
      ) {
        edges {
          node {
            id
            data {
              name: Name
              value: Value
            }
          }
        }
      }
    }
  `);

  return (
    <StepWrap title="Select Font">
      {data.fontList.edges.map(({ node: { id, data: { name, value } } }) => (
        <Choice
          key={id}
          value={value}
          name="font"
          text={name}
          checked={item.font.value === value}
          style={{ fontFamily: fontFamily[value] }}
          onChange={(e) =>
            setItem({ ...item, font: { label: name, value: e.target.value } })
          }
        />
      ))}
    </StepWrap>
  );
};

export default Fonts;
