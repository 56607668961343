import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useRecoilState, useRecoilValue } from "recoil";

import { selectedItemAtom, itemAtom } from "../../utils/atoms";
import Choice from "../Choice/Choice";
import StepWrap from "../StepWrap/StepWrap";

const Colors = () => {
  const selectedItem = useRecoilValue(selectedItemAtom);
  const [item, setItem] = useRecoilState(itemAtom(selectedItem));

  const data = useStaticQuery(graphql`
    query ColorsQuery {
      colorList: allAirtable(
        filter: { table: { eq: "Colors" }, data: { Active: { eq: true } } }
      ) {
        edges {
          node {
            id
            data {
              name: Name
              primaryColor: Primary_Color
              secondaryColor: Secondary_Color
              backgroundColor: Background_Color
              iconColor: Icon_Color
              textColor: Text_Color
            }
          }
        }
      }
    }
  `);

  return (
    <StepWrap title="Select Color Palette">
      {data.colorList.edges.map(
        ({
          node: {
            id,
            data: {
              name,
              primaryColor,
              secondaryColor,
              backgroundColor,
              iconColor,
              textColor,
            },
          },
        }) => (
          <Choice
            key={id}
            value={JSON.stringify({
              name,
              primaryColor,
              secondaryColor,
              backgroundColor,
              iconColor,
              textColor,
            })}
            name="color"
            text={name}
            checked={
              item.color ===
              JSON.stringify({
                name,
                primaryColor,
                secondaryColor,
                backgroundColor,
                iconColor,
                textColor,
              })
            }
            onChange={(e) => setItem({ ...item, color: e.target.value })}
          >
            <div className="flex w-full h-14 px-8">
              <div
                className="w-full h-full"
                style={{ backgroundColor: primaryColor }}
              />
              <div
                className="w-full h-full"
                style={{ backgroundColor: secondaryColor }}
              />
              <div className="w-full h-full" style={{ backgroundColor }} />
              <div
                className="w-full h-full"
                style={{ backgroundColor: iconColor }}
              />
            </div>
          </Choice>
        )
      )}
    </StepWrap>
  );
};

export default Colors;
