import React from "react";
// import { useStaticQuery, graphql } from "gatsby";
import { useRecoilState, useRecoilValue } from "recoil";

import { selectedItemAtom, itemAtom } from "../../utils/atoms";
import Choice from "../Choice/Choice";
import StepWrap from "../StepWrap/StepWrap";

const Typestyles = () => {
  const selectedItem = useRecoilValue(selectedItemAtom);
  const [item, setItem] = useRecoilState(itemAtom(selectedItem));

  return (
    <StepWrap title="Select Type Style">
      <Choice
        value="1"
        name="typestyle"
        text="One Line"
        checked={item.typestyle.value === "1"}
        onChange={(e) =>
          setItem({
            ...item,
            typestyle: { label: "One Line", value: e.target.value },
          })
        }
      >
        <div className="ml-8 text-4xl whitespace-nowrap">
          {item.name} {item.name} {item.name} {item.name} {item.name}{" "}
          {item.name} {item.name}
          {item.name} {item.name} {item.name} {item.name} {item.name}{" "}
          {item.name} {item.name}
        </div>
      </Choice>
      <Choice
        value="2"
        name="typestyle"
        text="Stacked"
        checked={item.typestyle.value === "2"}
        onChange={(e) =>
          setItem({
            ...item,
            typestyle: { label: "Stacked", value: e.target.value },
          })
        }
      >
        <div className="ml-8 text-2xl whitespace-nowrap">
          {item.name} {item.name} {item.name} {item.name} {item.name}{" "}
          {item.name} {item.name}
          {item.name} {item.name} {item.name} {item.name} {item.name}{" "}
          {item.name} {item.name}
        </div>
        <div className="ml-8 text-2xl whitespace-nowrap">
          {item.name} {item.name} {item.name} {item.name} {item.name}{" "}
          {item.name} {item.name}
          {item.name} {item.name} {item.name} {item.name} {item.name}{" "}
          {item.name} {item.name}
        </div>
      </Choice>
      <Choice
        value="3"
        name="typestyle"
        text="Flip Stacked"
        checked={item.typestyle.value === "3"}
        onChange={(e) =>
          setItem({
            ...item,
            typestyle: { label: "Flip Stacked", value: e.target.value },
          })
        }
      >
        <div className="ml-8 text-2xl whitespace-nowrap pb-8">
          {item.name} {item.name} {item.name} {item.name} {item.name}{" "}
          {item.name} {item.name}
          {item.name} {item.name} {item.name} {item.name} {item.name}{" "}
          {item.name} {item.name}
        </div>
        <div className="ml-8 text-2xl whitespace-nowrap absolute transform -translate-y-8 rotate-180">
          {item.name} {item.name} {item.name} {item.name} {item.name}{" "}
          {item.name} {item.name}
          {item.name} {item.name} {item.name} {item.name} {item.name}{" "}
          {item.name} {item.name}
        </div>
      </Choice>
      <Choice
        value="4"
        name="typestyle"
        text="Alternating"
        checked={item.typestyle.value === "4"}
        onChange={(e) =>
          setItem({
            ...item,
            typestyle: { label: "Alternating", value: e.target.value },
          })
        }
      >
        <div className="ml-8 whitespace-nowrap flex items-center">
          <div className="text-2xl mr-4">
            <div>{item.name}</div>
            <div>{item.name}</div>
          </div>
          <div className="text-4xl mr-4">{item.name}</div>
          <div className="text-2xl mr-4">
            <div>{item.name}</div>
            <div>{item.name}</div>
          </div>
          <div className="text-4xl mr-4">{item.name}</div>
          <div className="text-2xl mr-4">
            <div>{item.name}</div>
            <div>{item.name}</div>
          </div>
          <div className="text-4xl mr-4">{item.name}</div>
          <div className="text-2xl mr-4">
            <div>{item.name}</div>
            <div>{item.name}</div>
          </div>
          <div className="text-4xl mr-4">{item.name}</div>
        </div>
      </Choice>
  </StepWrap>
)};

export default Typestyles;
